import React from "react";
// import { FaBell } from "react-icons/fa";
const imgList = [
  "http://en.yageegroup.com/wp-content/themes/yagee/images/icon/footer-icon-1.png",
  "http://en.yageegroup.com/wp-content/themes/yagee/images/icon/footer-icon-2.png",
  "http://en.yageegroup.com/wp-content/themes/yagee/images/icon/footer-icon-3.png",
  "http://en.yageegroup.com/wp-content/themes/yagee/images/icon/footer-icon-4.png",
  "http://en.yageegroup.com/wp-content/themes/yagee/images/icon/footer-icon-5.png",
  "http://en.yageegroup.com/wp-content/themes/yagee/images/icon/footer-icon-6.png",
];

const Footer = () => {
  return (
    <div className="footerMainContainer bg-white">
      <div className="footerSection1 flex flex-wrap justify-between p-5">
        <div className="section1a text-xs font-normal mt-3">
          <p>Copyright ©2024 shhopes Group.</p>
          <p>
            shhopes® is trademarks of  shhopes Group. All other trademarks are the
            property of their respective owners.
          </p>
          {/* <p className="flex mt-2">
            Choose your country or region
            <span className="flex">
              <img
                className="mx-1"
                alt="us-icon"
                src={require("../../assets/images/us-icon.png")}
              />
              <img
                className="mx-1"
                alt="cn-icon"
                src={require("../../assets/images/cn-icon.png")}
              />
            </span>
          </p>
          <p className="mt-3">
            Web Development by Anttoweb Web Design. 沪ICP备10216470号
          </p> */}
        </div>
        <div className="section1b flex bg-red-500 ">
          {imgList.map((item, index) => {
            return (
              <img className="w-[60px] h-[60px]"
                alt={`icon of partner ${index + 1}`}
                src={require(`../../assets/images/icon${index + 1}.png`)}
                key={index}
              />
            );
          })}
        </div>
      </div>
      {/* <div className="footerSection2 ">
        <FaBell className="ml-8 my-5 text-blue-700 " />
      </div> */}
    </div>
  );
};

export default Footer;
