import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./styles.css";

import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";

const FadedSlider = () => {
  // const img1 = require("../../assets/images/pico1.jpg");
  const img2 = require("../../assets/images/pico2.jpg");
  const img3 = require("../../assets/images/pico3.jpg");
  const img4 = require("../../assets/images/pico4.jpg");
  const imgList = [
    // img1,
     img2, img3,img4];
  return (
    <>
      <Swiper
        spaceBetween={30}
        loop={true}
        effect={"fade"}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="mySwiper  "
      >
        {imgList.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <img
                alt={`background number ${index + 1}`}
                src={item}
              />
            </SwiperSlide>
          );
        })}
        {/* <SwiperSlide>
          <img
            src="http://en.yageegroup.com/wp-content/uploads/2018/09/2017083108272054-1.jpg"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="http://en.yageegroup.com/wp-content/uploads/2016/01/slider04.jpg"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="http://en.yageegroup.com/wp-content/uploads/2014/01/slider01-1.jpg"
          />
        </SwiperSlide> */}
      </Swiper>
    </>
  );
};

export default FadedSlider;
