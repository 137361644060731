import React from "react";
import BrandCard from "../BrandCard/BrandCard";

const BrandSection = () => {
  // const logo1 
  const data = [
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107251583.jpg",
    // logo1,

    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107251675.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107251623.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107251774.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107251792.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107251837.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107251942.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107251959.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107252062.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107252072.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107252162.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107252146.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107252235.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107252268.jpg",
    // "http://en.yageegroup.com/wp-content/uploads/2017/08/2017083107252617.jpg",
    
    require('../../assets/images/logoFiles/logo1.jpeg'),
    require('../../assets/images/logoFiles/logo2.png'),
    require('../../assets/images/logoFiles/logo3.png'),
    require('../../assets/images/logoFiles/logo4.png'),
    require('../../assets/images/logoFiles/logo5.png'),
    require('../../assets/images/logoFiles/logo6.png'),
    require('../../assets/images/logoFiles/logo7.png'),
    require('../../assets/images/logoFiles/logo8.png'),
    require('../../assets/images/logoFiles/logo9.png'),
    require('../../assets/images/logoFiles/logo10.png'),
    require('../../assets/images/logoFiles/logo11.png'),
    require('../../assets/images/logoFiles/logo12.jpg'),
    require('../../assets/images/logoFiles/logo13.png'),
    require('../../assets/images/logoFiles/logo14.png'),
    require('../../assets/images/logoFiles/logo15.jpg')
  ];
  return (
    <div className="flex flex-wrap items-center justify-center">
      {data.map((item, index) => {
        return <BrandCard  src={item} key={index} />;
      })}
    </div>
  );
};

export default BrandSection;


