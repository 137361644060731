import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { FaHome } from "react-icons/fa";

const Profile = () => {
  const breadcrumbList = [
    { title: <FaHome className="text-custmBlue" />, path: "/" },
    { title: "Company", path: "/profile" },
  ];

  const data = [
    `
    欢迎来到 Shanghai ATC Hopes Import & Export Co. Ltd，这里是汽车创新与全球卓越的融合。作为高性能汽车电子产品的主要供应商，我们为提供各种顶级产品而感到自豪，从最先进的布线解决方案到精密设计的连接器、先进的传感器和用于线束维护的专用工具。`,
    `Shanghai ATC Hopes  的使命是为爱好者和专业人士提供突破汽车性能极限所需的工具。凭借对质量和客户满意度的不懈承诺，我们力求在每一个环节都超越您的期望。无论您是开始专业赛车事业还是寻求增强您的个人车辆的功能，我们的团队随时准备为您的每一步提供无与伦比的服务和支持。`,
    `  体验 Shanghai ATC Hopes Import & Export Co. Ltd 的与众不同，对卓越汽车的热情满足全球贸易的最高标准。与我们一起推动高性能汽车电子产品的未来，一次一个创新解决方案。`,
    // "Shanghai  shhopes Co., Ltd. was founded in 2008. Over the years, Yagee has developed a comprehensive and diversified business network with close links with suppliers and customers. Yagee is a professional import connector distributor and authorized by TE in September 2018. Our philosophy is to distribute the most advantageous products from all over the world to the best customers, saving customers time and allowing our customers to enjoy our most professional services.",
    // " shhopes has more than 1,000 customers from different industries, such as consumer electronics, household appliances, industrial, telecommunications and electronic manufacturing services, automotive and medical. The company’s main suppliers include TE, Molex, JST, 3M, Delphi, FCI, Harting, Hirose, ITT CANNON, JAE, JST, KET, Lear/G&H, YEONHO, Phoenix, Toughcon, Schurter, YEONHO, etc.",
    // " shhopes has 6 offices and 2 logistics centers in Shanghai, Shenzhen, Chengdu, Beijing and Hong Kong, with a total of more than 100 employees. Yagee has always maintained close contact with its partners, striving to shorten the time to market and response time and actively improve inventory management. It aims to meet customers’ needs for engineering and supply chain in the first place, and has become a success for customers and suppliers.",
    // " shhopes firmly believes in and always adheres to the “customer first, integrity-based” business policy, and is committed to developing together with employees, customers, partners and suppliers, and is striving to become the most potential distributor in the connector field.",
    // "Looking to the future,  shhopes is making breakthroughs in e-commerce and logistics, and is entering a new chapter of development. Through continuous improvement,  shhopes will definitely help partners to win more competitive in the future market.",
  ];

  return (
    <div>
      <Breadcrumbs crumbs={breadcrumbList} />
      <p className="text-custmBlue text-3xl font-medium ml-5 mt-5">shhopes</p>
      <div className="flex w-[80vw] mx-auto mt-4">
        <img
          alt="background of profile page"
          // src="http://en.yageegroup.com/wp-content/uploads/2014/01/about-us.jpg"
          src={require("../../assets/images/profilebg.jpg")}
        />
      </div>
      <div className="flex flex-col w-[90vw] mx-auto my-4">
        {data.map((item, index) => {
          return (
            <p className="my-2 text-customGray text-sm font-normal" key={index}>
              {item}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default Profile;
