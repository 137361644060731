import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation

const Breadcrumbs = ({ crumbs }) => {
  if (crumbs.length <= 1) {
    // Don't render a breadcrumb component if there's only one item
    return null;
  }

  return (
    <nav className="text-gray-500 text-sm font-medium ml-5 mt-5" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        {crumbs.map((crumb, i) => (
          <li key={i} className="flex items-center">
            <Link to={crumb.path} className={`text-blue-600 hover:text-blue-800 ${i !== crumbs.length - 1 ? 'mr-2' : ''}`}>
              {crumb.title}
            </Link>
            {i < crumbs.length - 1 && ( 
              <svg className="fill-current w-3 h-3 mx-2" viewBox="0 0 20 20">
                <path d="M7.293 14.707a1 1 0 01-1.414-1.414L9.586 10 5.879 6.293a1 1 0 111.414-1.414l4.707 4.707a1 1 0 010 1.414l-4.707 4.707z" />
              </svg>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
