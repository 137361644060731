import React from "react";
import FadedSlider from "../../components/FadedSlider/FadedSlider";
import Heading from "../../components/Heading/Heading";
import BrandSection from "../../components/BrandSection/BrandSection";
import InquirySection from "../../components/InquirySection/InquirySection";
import ApplicationSection from "../../components/ApplicationSection/ApplicationSection";

const Home = () => {
  return (
    <div>
      <FadedSlider />
      <Heading heading="经销品牌" />
      <BrandSection />
      <div className="bg-white pt-4">
        <Heading heading="在线咨询" />
        <div className="subHeading flex justify-center">
          <p className="my-0 mx-2 text-customGray">在线咨询,</p>
          <p className="my-0 mx-2 text-customGray">快速响应,</p>
          <p className="my-0 mx-2 text-customGray">专业团队</p>
        </div>
        <InquirySection />
      </div>
      <Heading heading="应用行业" />
      <ApplicationSection />
    </div>
  );
};

export default Home;
