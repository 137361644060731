import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";
import { Autoplay, Navigation } from "swiper/modules";

const ApplicationSection = () => {
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const size1 = windowWidth < 640;
  const size2 = windowWidth >= 640 && windowWidth < 768;
  const size3 = windowWidth >= 768 && windowWidth < 1024;
  const size4 = windowWidth >= 1024 && windowWidth < 1280;
  const size5 = windowWidth >= 1280 && windowWidth < 1536;
  const size6 = windowWidth >= 1536;

  const data = [
    {
      text: "医疗保健行业",
      src: "http://en.yageegroup.com/wp-content/uploads/2014/02/app_1.jpg",
    },
    {
      text: "家电行业",
      src: "http://en.yageegroup.com/wp-content/uploads/2014/02/app_2.jpg",
    },
    {
      text: "电梯、工业机械及工控行业",
      src: "http://en.yageegroup.com/wp-content/uploads/2014/02/app_3.jpg",
    },
    {
      text: "通讯及网络行业",
      src: "http://en.yageegroup.com/wp-content/uploads/2014/02/app_4.jpg",
    },

    {
      text: "太阳能及新能源产业",
      src: "http://en.yageegroup.com/wp-content/uploads/2014/02/app_5.jpg",
    },
    {
      text: " 汽车、工程车辆及运输轨道行业",
      src: "http://en.yageegroup.com/wp-content/uploads/2014/02/app_6.jpg",
    },
    {
      text: "船舶及航空航天工业",
      src: "http://en.yageegroup.com/wp-content/uploads/2014/02/app_7.jpg",
    },
  ];
  return (
    <div className="my-20 ">
      <Swiper
        slidesPerView={
          size1
            ? 1
            : size2
            ? 2
            : size3
            ? 3
            : size4
            ? 4
            : size5
            ? 5
            : size6
            ? 6
            : 1
        }
        spaceBetween={30}
        navigation={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        s
        modules={[Autoplay, Navigation]}
        className="mySwiper "
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide key={index} >
              <div className="flex flex-col hover:cursor-pointer w-64 h-48 bg-gray-100">
                <img
                  className="w-60 h-40"
                  alt={`application number ${index + 1}`}
                  src={require(`../../assets/images/app${index + 1}.jpg`)}
                />
                <p className="text-custmBlue text-sm font-medium">
                  {item.text}
                </p>
              </div>
            </SwiperSlide>
          );
        })}
        {/* <SwiperSlide>Slide 1</SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide> */}
      </Swiper>
    </div>
  );
};

export default ApplicationSection;
