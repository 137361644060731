import React from "react";

const BrandCard = ({ src }) => {
  return (
    <div className="brandCardMainContainer w-60 h-36 mx-1.5 my-2  flex items-center justify-center border border-gray-400">
      <img
        className="w-[130px] h-[40px]
       "
      //   className="w-[230px] h-[80px]
      //  "
        alt="brand logo"
        src={src}
      />
    </div>
  );
};

export default BrandCard;
