import React from "react";
// import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="navbarMainContainer flex flex-col lg:flex-row flex-wrap justify-between items-center p-3 bg-white">
      <div className="navbarContainer1">
        <img
          alt="logo"
          className="w-[100px] h-[70px]"
          src={require("../../assets/images/logo.png")}
        />
      </div>
      <div className="navbarContainer2">
        <ul className=" flex flex-col lg:flex-row  justify-around w-[40vw] ">
          <Link to="">
            <li className="cursor-pointer text-lg text-custmBlue font-medium hover:font-bold hover:text-black transition-all duration-300">
              家
            </li>
          </Link>
          <Link to="profile">
            <li className="cursor-pointer text-lg text-custmBlue font-medium hover:font-bold hover:text-black transition-all duration-300">
              公司简介
            </li>
          </Link>
          <Link to="product-center">
            <li className="cursor-pointer text-lg text-custmBlue font-medium hover:font-bold hover:text-black transition-all duration-300">
              产品中心
            </li>
          </Link>

          <Link to="contact">
            <li className="cursor-pointer text-lg text-custmBlue font-medium hover:font-bold hover:text-black transition-all duration-300">
              产品中心
            </li>
          </Link>
        </ul>
      </div>
      {/* <div className="navbarContainer3">
            <span className="bg-gray-100 flex items-center justify-around border-solid border-2 p-1">
            <input className="bg-gray-100 outline-none" placeholder="TE/molex" />
            <FaSearch />
            </span>
        </div> */}
    </div>
  );
};

export default Navbar;
