import React from "react";

export default function ProductCenter() {
  return (
    <div>
      <h1 className="text-3xl font-normal text-center">ProductCenter</h1>
      <h2 className="text-6xl font-bold text-center">Under Development</h2>
    </div>
  );
}
