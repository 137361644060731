import React from "react";
import InquiryCard from "../InquiryCard/InquiryCard";

const InquirySection = () => {
  // const img1 = require("../../assets/images/pico1.jpg");
  // const img2 = require("../../assets/images/pico2.jpg");
  // const img3 = require("../../assets/images/pico3.jpg");
  // const img4 = require("../../assets/images/pico4.jpg");
  const data = [
    {
      src: "http://en.yageegroup.com/wp-content/uploads/2017/08/home-stock01.png",
      heading: "泰科授权",
      subHeading: "正式渠道",
    },
    {
      src: "http://en.yageegroup.com/wp-content/uploads/2017/08/home-stock02.png",
      heading: "最小起订量=海贼王",
      subHeading: "支持批量",
    },
    {
      src: "http://en.yageegroup.com/wp-content/uploads/2017/08/home-stock03.png",
      heading: "快速响应",
      subHeading: "2小时内回复",
    },
    {
      src: "http://en.yageegroup.com/wp-content/uploads/2017/08/home-stock04.png",
      heading: "特价",
      subHeading: "高效服务",
    },
  ];
  return (
    <div className="inqurySectionMainContainer flex justify-around w-[90vw] mx-auto my-14 flex-wrap bg-white">
      {data.map((item, index) => {
        return (
          <InquiryCard
            key={index}
            // src={item.src}
            src={require(`../../assets/images/inq${index + 1}.png`)}
            heading={item.heading}
            subHeading={item.subHeading}
          />
        );
      })}
    </div>
  );
};

export default InquirySection;
