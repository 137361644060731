// import React from "react";
// import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
// import { FaHome } from "react-icons/fa";
// // import ContactInfo from "../../components/ContactInfo/ContactInfo";
// const Contact = () => {
//   const breadcrumbList = [
//     { title: <FaHome className="text-custmBlue" />, path: "/" },
//     { title: "Contact Us", path: "/contact" },
//   ];

//   // const data = [
//   // {
//   //   name: "Shanghai Headquarters:",
//   //   address:
//   //     "Address: 1-4/F, West Block, Cao’an Business Park, No. 4588 Cao’an Road, Jiading District, Shanghai",
//   //   tel: "Tel: (8621) 60132758/60132757/60132760",
//   //   fax: "Fax: (8621) 60132755",
//   //   email: " mark@yageegroup.com",
//   // },
//   // {
//   //   name: "Shenzhen：",
//   //   address:
//   //     "Address: 3/F, Building C, Building B, Junxing Industrial Park, Fuyuan 2nd Road, Fuyong Street, Baoan District, Shenzhen, China",
//   //   tel: "Tel: (0755) 23595592-801/802/803",
//   //   fax: "Fax: (0755) 23595582",
//   //   email: " mark@yageegroup.com",
//   // },
//   // {
//   //   name: "Hong Kong：",
//   //   address:
//   //     "Address: 902 Weida Centre, 8 On Ping Street, Siu Lek Yuen, Sha Tin, New Territories, Hong Kong.",
//   //   tel: "Tel: (00852) 2944 0800",
//   //   fax: "",
//   //   email: " thomas.wang@yageegroup.com",
//   // },
//   // {
//   //   name: "Beijing：",
//   //   address:
//   //     "Address: Room 118, Building 1, Xinxin Block, Huilongguan, Changping District, Beijing",
//   //   tel: "Tel: 15618041849",
//   //   fax: "",
//   //   email: " thomas.wang@yageegroup.com",
//   // },
//   // ];
//   return (
//     <div className="contactMainContainer">
//       <div className="flex w-[98vw] mx-auto">
//         <img
//           alt="background of contact page"
//           // src="http://en.yageegroup.com/wp-content/themes/yagee/images/headers/banner_default.jpg"
//           src={require("../../assets/images/contactbg.jpg")}
//         />
//       </div>
//       <Breadcrumbs crumbs={breadcrumbList} />
//       <div className="contactSection2 w-[85vw] mx-auto flex justify-between m-10">
//         <div className="contactSection2a">
//           <p className="text-lg font-medium">Shanghai shhopes Co., Ltd.</p>
//           <p className="text-customGray">Product Hotline: (8621) 60132758</p>
//           <p className="text-customGray">Enterprise QQ: 2880339137</p>
//           <div className="flex flex-col">
//             {/* {data.map((item, index) => {
//               return (
//                 <ContactInfo
//                   key={index}
//                   name={item.name}
//                   address={item.address}
//                   tel={item.tel}
//                   fax={item.fax}
//                   email={item.email}
//                 />
//               );
//             })} */}
//             {/* <ContactInfo
//               name={`SHANGHAI ATC HOPES IMPORT AND EXPORT COMPANY LIMITED`}
//               address={`Room 1105, No.6 Lane 1188, Yun Ping Road, Jiading District Shanghai, 201821, CHINA`}
//               mobile={`Mobile: 15901997200`}
//               email={`Abdullahnadeem96@gmail.com`}
//               postCode={201821}
//             /> */}

//             <div className="my-8 w-[90%] text-customGray">
//               <p className="font-bold text-sm">
//                 Adress: SHANGHAI ATC HOPES IMPORT AND EXPORT COMPANY LIMITED
//               </p>
//               <p>
//                 Room 1105, No.6 Lane 1188, Yun Ping Road, Jiading District
//                 Shanghai, 201821, CHINA
//               </p>
//               <p>Contact us 134 8256 4668</p>
//               <p>Post Code: 201821</p>
//               <p>
//                 E-mail:
//                 <span className="text-custmBlue">Liupiing@shhopes.com</span>
//               </p>
//               <p>
//                 E-mail:
//                 <span className="text-custmBlue">JessicaLee@shhopes.com</span>
//               </p>
//               <p>
//                 E-mail:
//                 <span className="text-custmBlue">Victor@shhopes.com</span>
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="contactSection2b">
//           <div className="form  w-[40vw] p-3">
//             <div className="flex flex-col">
//               <label
//                 htmlFor="unit"
//                 className="font-bold text-sm text-customGray"
//               >
//                 Name<span className="text-red-700">*</span>
//               </label>
//               <input
//                 id="unit"
//                 className="bg-gray-200 outline-none w-[100%] p-1.5 m-2 border border-gray-400 shadow-2xl"
//                 type="text"
//               />
//             </div>
//             <div className="flex flex-col">
//               <label
//                 htmlFor="email"
//                 className="font-bold text-sm text-customGray"
//               >
//                 Email<span className="text-red-700">*</span>
//               </label>
//               <input
//                 id="email"
//                 className="bg-gray-200 outline-none w-[100%] p-1.5 m-2 border border-gray-400 shadow-2xl"
//                 type="email"
//               />
//             </div>
//             <div className="flex flex-col">
//               <label
//                 htmlFor="tel"
//                 className="font-bold text-sm text-customGray"
//               >
//                 Tel<span className="text-red-700">*</span>
//               </label>
//               <input
//                 id="tel"
//                 className="bg-gray-200 outline-none w-[100%] p-1.5 m-2 border border-gray-400 shadow-2xl"
//                 type="number"
//               />
//             </div>
//             <div className="flex flex-col">
//               <label
//                 htmlFor="company-name"
//                 className="font-bold text-sm text-customGray"
//               >
//                 Company Name
//               </label>
//               <input
//                 id="company-name"
//                 className="bg-gray-200 outline-none w-[100%] p-1.5 m-2 border border-gray-400 shadow-2xl"
//                 type="text"
//               />
//             </div>
//             <div className="flex flex-col">
//               <label
//                 htmlFor="message"
//                 className="font-bold text-sm text-customGray"
//               >
//                 Message
//               </label>
//               <textarea
//                 id="message"
//                 className="bg-gray-200 outline-none border  border-gray-400 shadow-2xl"
//                 name=""
//                 rows="7"
//               ></textarea>
//             </div>
//             {/* <div className="flex flex-col">
//               <img
//                 alt="captcha"
//                 src="http://en.yageegroup.com/wp-content/uploads/wpcf7_captcha/638386732.png"
//                 className="w-20 h-7 mt-5 mb-2"
//               />
//               <input
//                 id="company-name"
//                 className="bg-gray-200 outline-none w-[100%] p-1.5 m-2 border border-gray-400 shadow-2xl"
//                 type="text"
//               />
//             </div> */}
//             <button className="bg-custmBlue text-white w-[100%] ml-2 mt-2 p-1 text-sm font-normal">
//               Submit
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Contact;


import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { FaHome } from "react-icons/fa";

const Contact = () => {
  const breadcrumbList = [
    { title: <FaHome className="text-custmBlue" />, path: "/" },
    { title: "Contact Us", path: "/contact" },
  ];

  return (
    <div className="contactMainContainer">
      <div className="flex w-full mx-auto">
        <img
          alt="background of contact page"
          src={require("../../assets/images/contactbg.jpg")}
          className="w-full object-cover"
        />
      </div>
      <Breadcrumbs crumbs={breadcrumbList} />
      <div className="contactSection2 w-full md:w-[85vw] mx-auto flex flex-col md:flex-row justify-between m-4 md:m-10">
        <div className="contactSection2a w-full md:w-[50%]">
          {/* <p className="text-lg font-medium">Shanghai shhopes Co., Ltd.</p>
          <p className="text-customGray">Product Hotline: (8621) 60132758</p>
          <p className="text-customGray">Enterprise QQ: 2880339137</p> */}
          <div className="flex flex-col mt-6">
            <div className="my-8 w-full md:w-[90%] text-customGray">
              <p className="font-bold text-sm">
                Adress: SHANGHAI ATC HOPES IMPORT AND EXPORT COMPANY LIMITED
              </p>
              <p>
                Room 1105, No.6 Lane 1188, Yun Ping Road, Jiading District
                Shanghai, 201821, CHINA
              </p>
              <p>Contact Us: 134 8256 4668</p>
              <p>Post Code: 201821</p>
              <p>
                E-mail:
                <span className="text-custmBlue">Liupiing@shhopes.com</span>
              </p>
              <p>
                E-mail:
                <span className="text-custmBlue">JessicaLee@shhopes.com</span>
              </p>
              <p>
                E-mail:
                <span className="text-custmBlue">Victor@shhopes.com</span>
              </p>
            </div>
          </div>
        </div>
        <div className="contactSection2b w-full md:w-[45%] mt-6 md:mt-0">
          <div className="form w-full p-3">
            <div className="flex flex-col">
              <label
                htmlFor="unit"
                className="font-bold text-sm text-customGray"
              >
                Name<span className="text-red-700">*</span>
              </label>
              <input
                id="unit"
                className="bg-gray-200 outline-none w-full p-1.5 m-2 border border-gray-400 shadow-2xl"
                type="text"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="email"
                className="font-bold text-sm text-customGray"
              >
                Email<span className="text-red-700">*</span>
              </label>
              <input
                id="email"
                className="bg-gray-200 outline-none w-full p-1.5 m-2 border border-gray-400 shadow-2xl"
                type="email"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="tel"
                className="font-bold text-sm text-customGray"
              >
                Tel<span className="text-red-700">*</span>
              </label>
              <input
                id="tel"
                className="bg-gray-200 outline-none w-full p-1.5 m-2 border border-gray-400 shadow-2xl"
                type="number"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="company-name"
                className="font-bold text-sm text-customGray"
              >
                Company Name
              </label>
              <input
                id="company-name"
                className="bg-gray-200 outline-none w-full p-1.5 m-2 border border-gray-400 shadow-2xl"
                type="text"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="message"
                className="font-bold text-sm text-customGray"
              >
                Message
              </label>
              <textarea
                id="message"
                className="bg-gray-200 outline-none w-full border border-gray-400 shadow-2xl"
                rows="7"
              ></textarea>
            </div>
            <button className="bg-custmBlue text-white w-full mt-4 p-2 text-sm font-normal">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
