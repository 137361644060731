import React from 'react'

const InquiryCard = ({src,heading,subHeading}) => {
  return (
    <div className='inquiryCardMainContainer flex flex-col items-center'>
        <img alt='display icon of service' src={src}  />
        <p className='text-customGray2 font-medium text-sm mt-2'>{heading}</p>
        <p className='text-customGray font-normal text-sm'>{subHeading}</p>
    </div>
  )
}

export default InquiryCard